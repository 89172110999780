import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {

  items: Observable<any[]>;

  constructor(private db: AngularFireDatabase, private http: HttpClient) {
    this.items = db.list('items').valueChanges();
  }

  submitting = false;
  submitted = false;

  ngOnInit() {

  }

  profileForm = new FormGroup({
    nombre: new FormControl('', Validators.required),
    apellido: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    telefono: new FormControl('', Validators.required),
    areaTexto: new FormControl('', Validators.required),
  });

  get f() { return this.profileForm.controls; }


  enviarFormulario() {
    this.submitted = true;
    console.log(this.profileForm.value);

    if (this.profileForm.invalid) {
      console.log('error');
      return;
    }

    this.submitting = true;



    //his.db.list('bienvenida').push({prueba: "hola", prueba2 : "k ase"});

    let html = 'Nombre: ' + this.profileForm.value.nombre + '\n' + 'Apellido: ' + this.profileForm.value.apellido + '\n' +
      'Email: ' + this.profileForm.value.email + '\n' + 'Teléfono: ' + this.profileForm.value.telefono + '\n' + 'Mensaje: ' + this.profileForm.value.areaTexto;


      

    this.http.post<any>('https://us-central1-enpolicial-bd555.cloudfunctions.net/mailer', { to: 'enpolicialcontacto@gmail.com', message: html, subject: 'Duda' }).subscribe(data => {
      if (data.message == 'email sent') {
        //console.log('Correo enviado correctamente');
        Swal.fire({
          icon: 'success',
          title: 'Correo enviado correctamente',
          text: 'Gracias por ponerte en contacto con EnPolicial, tardaremos lo minimo posible en responderte',
        })
        this.submitting = false;
        this.submitted = false;
        this.profileForm.reset();

      }
    });

  }

}
