import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SobreNosotrosComponent } from './sobre-nosotros/sobre-nosotros.component';
import { NuestraPreparacionComponent } from './nuestra-preparacion/nuestra-preparacion.component';
import { NuestroMetodoComponent } from './nuestro-metodo/nuestro-metodo.component';
import { PreguntasFrecuentesComponent } from './preguntas-frecuentes/preguntas-frecuentes.component';
import { ContactoComponent } from './contacto/contacto.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularFireModule} from '@angular/fire';
import { AngularFireDatabaseModule} from '@angular/fire/database';
import {environment} from '../environments/environment';

import { HttpClientModule } from '@angular/common/http';






export const routes: Routes = [

  { path: '', component: MainComponent },
  { path: 'nosotros',  component: SobreNosotrosComponent},
  { path: 'metodo',  component: NuestroMetodoComponent},
  { path: 'preparacion',  component: NuestraPreparacionComponent},
  { path: 'preguntas-frecuentes',  component: PreguntasFrecuentesComponent},
  { path: 'contacto',  component: ContactoComponent}
]




@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    SobreNosotrosComponent,
    NuestraPreparacionComponent,
    NuestroMetodoComponent,
    PreguntasFrecuentesComponent,
    ContactoComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
