import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuestra-preparacion',
  templateUrl: './nuestra-preparacion.component.html',
  styleUrls: ['./nuestra-preparacion.component.css']
})
export class NuestraPreparacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  boton = 'Más información';
  pulsado = false;

  cambiarTextoBoton(text){
    
    if(text.innerText == 'Más información'){
      text.innerText = 'Ocultar información';
    }else{
      text.innerText = 'Más información';
    }

  }


}
