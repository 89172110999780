import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuestro-metodo',
  templateUrl: './nuestro-metodo.component.html',
  styleUrls: ['./nuestro-metodo.component.css']
})
export class NuestroMetodoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
